import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const CookiePolicy: React.FC<PageProps<DataProps>> = ({ data, path }) => (
  <Layout>
    <SEO title="Cookie Policy" />
    <h1>Which cookie policy do you expect?</h1>
    <p>
      It's my personal single page application, come on..
    </p>
    <p>
        I don't track you, so no worries. There is no Google Analytics or Facebook Pixel because, for some reason, it was difficult to tune it.
        <br />
        Instead of Google Analytics, I use this open-source project called <a href="https://www.goatcounter.com/">goatcounter</a>.
        <br />
        You can reach a source code of the project <a href="https://github.com/arp242/goatcounter">here</a>.

        Near to it, I use analytics platform from <a href="ya.ru">Yandex</a> called <a href="https://metrica.yandex.com/about?">Metrika</a>.
    </p>

    <p>
      You're currently on the page "{path}" which was built on{" "}
      {data.site.buildTime}.
    </p>
    <p>
        By the way, this page is also written just for fun using Typescript.
        <br />
      To learn more, head over to{" "}
      <a href="https://www.gatsbyjs.com/docs/typescript/">
        documentation about TypeScript
      </a>
      .
    </p>
    Better go back to the <Link to="/">Main</Link> page and don't be a nerd.
  </Layout>
)

export default CookiePolicy

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
